<template>
    <div class="page">
        <div
            v-if="loggedIn"
            class="container padded"
        >
            <div
                v-if="intranet"
                class="home"
            >
                <dashboard-news/>
                <div class="mbottom-medium"></div>
                <NoIntegrationsHint/>
                <div class="grid pbottom-medium">
                    <div class="col two-thirds">
                        <story-teaser/>
                        <div class="flex flex-space-between">
                            <h3>{{ $t("dashboard_posts_title") }}</h3>
                            <div>
                                <router-link
                                    :to="{ name: 'posts-single-topic', params: { topicUid: 'all' } }"
                                    class="hover-underline small-text text-high-contrast"
                                >
                                    {{ $t("dashboard_all_posts_label") }}
                                </router-link>
                            </div>
                        </div>
                        <PostTimeLine
                            :topic-uids="subscribedTopicUidsSnapshot"
                            :loading="!subscribedTopicsLoaded"
                        />
                    </div>
                    <div class="col side padding-small">
                        <h3 class="side-column-title mtop-xsmall mbottom-small">
                            {{ $t("hey", [currentUser.displayName]) }}
                        </h3>
                        <profile-progress-widget/>
                        <social-inbox class="mbottom-small"/>
                        <HappinessWidget/>
                        <social-happenings-overview class="mbottom-xsmall"/>
                        <GoogleCalendarActivity/>
                        <div class="card depth-1 padding-medium mbottom-small">
                            <h3 class="your-events-headline">
                                <span>{{ $t("events_upcoming") }}</span>
                                <router-link :to="'/intranet/' + intranet.uid + '/event'">
                                    <a class="link events-show-all">{{ $t("show_all") }}</a>
                                </router-link>
                            </h3>
                            <EventList
                                :limit="5"
                                disable-load-more
                                event-list-key="publishedUpcoming"
                                mode="dashboard"
                            >
                                <template slot="no-events">
                                    <i18n
                                        for="events_hub"
                                        path="events_empty_list_hub_hint"
                                        tag="span"
                                    >
                                        <router-link :to="'/intranet/' + intranet.uid + '/event'">
                                            <a class="link">{{ $t("events_hub") }}</a>
                                        </router-link>
                                    </i18n>
                                </template>
                            </EventList>
                        </div>

                        <div
                            v-if="confluenceAndJiraConnected && !isUserConnected"
                            class="mbottom-small"
                        >
                            <AtlassianAccessWrapper
                                :product="'confluence'"
                                class="card depth-1 padding-medium"
                            />
                        </div>
                        <div
                            v-if="onlyConfluenceConnected || (confluenceAndJiraConnected && isUserConnected)"
                            class="mbottom-small"
                        >
                            <AtlassianAccessWrapper
                                :product="'confluence'"
                                class="card depth-1 padding-medium"
                            >
                                <ConfluenceActivity/>
                            </AtlassianAccessWrapper>
                        </div>

                        <div class="mbottom-small">
                            <AtlassianAccessWrapper
                                v-if="onlyJiraConnected || (confluenceAndJiraConnected && isUserConnected)"
                                :product="'jira'"
                                class="card depth-1 padding-medium mbottom-large"
                            >
                                <JiraActivity/>
                            </AtlassianAccessWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import Vue from "vue";
import {
    mapGetters,
    mapState,
} from "vuex";
import AtlassianAccessWrapper from "@web/views/atlassian/AtlassianAccessWrapper.vue";
import ConfluenceActivity from "@web/views/atlassian/ConfluenceActivity.vue";
import JiraActivity from "@web/views/atlassian/JiraActivity.vue";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { LOGGED_IN } from "@web/store/auth/getters";
import GoogleCalendarActivity from "@web/views/google/GoogleCalendarActivity.vue";
import NoIntegrationsHint from "@web/views/intranet/UserSettings/integration/NoIntegrationsHint.vue";
import { ATLASSIAN_MODULE_NAME } from "@web/store/atlassian/atlassian";
import PostTimeLine from "@web/views/posts/PostTimeLine.vue";
import EventList from "@web/views/events/EventList";
import StoryTeaser from "@web/views/story/StoryTeaser.vue";
import DashboardNews from "@/views/intranet/DashboardNews";
import SocialHappeningsOverview from "@/components/social-happenings/SocialHappeningsOverview";
import ProfileProgressWidget from "@/components/profile/ProfileProgressWidget";
import SocialInbox from "@/components/social-notifications/SocialInbox";
import HappinessWidget from "@/components/happiness/HappinessWidget";
import { PROFILE_MODULE_NAME } from "@/store/profile/profile";
import { CURRENT_USER_PROFILE } from "@/store/profile/getters";
import { TOPIC_SUBSCRIPTION_MODULE_NAME } from "@/store/topic-subscription/topic-subscription";
import {
    GET_TOPIC_UIDS,
    LOADED,
} from "@/store/topic-subscription/getters";

export default Vue.extend({
    name: "Dashboard",
    components: {
        SocialInbox,
        ProfileProgressWidget,
        SocialHappeningsOverview,
        EventList,
        NoIntegrationsHint,
        AtlassianAccessWrapper,
        ConfluenceActivity,
        JiraActivity,
        GoogleCalendarActivity,
        PostTimeLine,
        StoryTeaser,
        DashboardNews,
        HappinessWidget,
    },
    props: {
        /**
         * message which is displayed when loading the dashboard
         *
         * @type {NotificationOptions | string}
         */
        message: {
            type: [Object, String],
            default: undefined,
        },
    },
    data() {
        return {
            /**
             * A snapshot of the subscribed topic uids to keep the viewing timeline while changing subscriptions and only update the timline by navigation.
             */
            subscribedTopicUidsSnapshot: [],
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        ...mapState(ATLASSIAN_MODULE_NAME, ["isUserConnected"]),
        ...mapGetters({
            loggedIn: AUTH_MODULE_NAME + LOGGED_IN,
            currentUser: PROFILE_MODULE_NAME + CURRENT_USER_PROFILE,
            subscribedTopicUids: TOPIC_SUBSCRIPTION_MODULE_NAME + GET_TOPIC_UIDS,
            subscribedTopicsLoaded: TOPIC_SUBSCRIPTION_MODULE_NAME + LOADED,
        }),
        atlassianFeatureEnabled() {
            return this.intranet.flags["atlassian-3lo"].enabled;
        },
        onlyConfluenceConnected() {
            return this.areAtlassianProductsConnected("confluence");
        },
        onlyJiraConnected() {
            return this.areAtlassianProductsConnected("jira");
        },
        confluenceAndJiraConnected() {
            return this.areAtlassianProductsConnected("confluence", "jira");
        },
    },
    watch: {
        subscribedTopicsLoaded: {
            handler(subscribedTopicsLoaded) {
                if (subscribedTopicsLoaded) {
                    this.subscribedTopicUidsSnapshot = this.subscribedTopicUids;
                }
            },
            immediate: true,
        },
    },
    mounted() {
        if (this.message) {
            this.$nextTick(() => this.$notify(this.message));
        }
    },
    methods: {
        areAtlassianProductsConnected(...products) {
            return this.intranet.flags["atlassian-3lo"].enabled &&
                this.intranet.flags["atlassian-3lo"].config.products.length === products.length &&
                products.every((p) => this.intranet.flags["atlassian-3lo"].config.products.includes(p));
        },
    },
});
</script>

<style lang="scss" scoped>

.grid {
    .col.two-thirds {
        max-width: 44rem;

        .card {
            height: 100%;
        }
    }

    .col.side {
        width: calc(33.33% - 3rem);
        flex: 1 1 calc(33.33% - 3rem);
        background-color: var(--lowest-contrast);
        border-radius: var(--border-radius);
        padding-bottom: 0;
        margin-left: 0;
    }
}

// override global grid styling
@media (max-width: 900px) {
    .col.side {
        max-width: 100% !important;
    }
}

.your-events-headline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        font-size: 1rem;
        color: var(--secondary);

        :hover {
            text-decoration: underline;
        }
    }
}
</style>
